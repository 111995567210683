import style from './style.scss';
import React from 'react';
import { Spin } from 'antd';

import Header from '../header';
import Footer from '../footer';

/**
 * @desc 页面整体框架组件
 */

export default function(props) {
    const { loading, route } = props;

    return (
        <div className={style.frame}>
            <Header {...props} />
            <div className={style.content}>
            <route.component {...props} />              
            </div>
            <Footer {...props} />
            {
                loading
                    ?
                    <div className={style.loading}><Spin size="large" /></div>
                    :
                    null
            }
        </div>
    );
}
