import React,{useEffect,useState} from 'react';
import style from './style.scss';
import { Table, ConfigProvider } from 'antd';
import NoTableData from '../../common/noTableData';

import {COLUMNS_INFO, ASSESS_INFO } from '../../constants/common';

    export default function(props) {
        const noData = [null, undefined, ''];

        // 页码修改
        const onPageChange = (pageNum) => {
            props.pageChange && props.pageChange(pageNum);              
        }

        const onRowClick = (record)=>{ 
            props.onRowClick && props.onRowClick(record);         
        }
    let columns = !props.columnType? COLUMNS_INFO : ASSESS_INFO;

    columns = [...columns.map(item => ({ ...item, render: item.render ? item.render : (text) => noData.includes(text) ? '--' : text }))];
        
    return (
        <div className={style.table}>
            <ConfigProvider renderEmpty={()=><NoTableData/>}>
                <Table
                    rowKey='id'
                    columns={columns }
                    loading={props.loading || false}
                    dataSource={props.data || []}
                    onRow={record => {
                        return {
                            onClick: () => onRowClick(record)
                        };
                    }}
                    pagination={{
                        total: props.total,
                        current: props.pageNum,
                        pageSize: props.pageSize,
                        showQuickJumper: true,
                        onChange: onPageChange
                    }}
                />
            </ConfigProvider>         
        </div>
    );
}