import React,{useEffect,useState} from 'react';
import { Table } from 'antd';
import { Route, Switch, Redirect, Link} from 'react-router-dom';
import  Frame  from  '../../frame'
// import styles from './style.scss';

import List from './list';
import Detail from './detail';

export const home = [
    {   // 列表
        path: 'list',
        name: '列表', 
        visible: true,
        component: List,
        exact: false
    },
    {   // 详情
        path: 'detail/:id',
        name: '详情', 
        visible: true,
        component: Detail,
        exact: false
    }
];

export default function (props) {
    const { match } = props;   
    return (
        <Switch>
            {
                home && home.map(o =>
                    <Route
                        key={o.path}
                        exact={o.exact}
                        path={`${match.path}/${o.path}`}
                        render={
                            prop => <o.component {...prop} {...props} Routes={home}/>
                        }
                    />
                )               
            } 
            <Redirect from={match.path} to={`${match.path}/list`} />                     
        </Switch>
    );
}
