import React, {useState, useEffect} from 'react';
import fetch from 'utils/fetch';

export default function(props) {
    const [name, setName] = useState('');

    useEffect(() => {
        fetch.preFetch = () => {
            this.setState({
                loading: true
            });
        };
        fetch.afterFetch = () => {
            this.setState({
                loading: false
            });
        };
    }, []); // 相当于componentDidMount

    useEffect(() => {
        console.log(name);
    }, [name]); // 相当于componentDidUpdate

    return {
        name
    };
}
