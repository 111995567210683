export const BannerData = {
        title: '关于我们',
        companyName:'成都数联铭品企业信用评估服务有限公司',
        desc:'成都数联铭品企业信用评估服务有限公司成立于2016年8月3日,主要经营范围涵盖企业信誉评估服务、企业资质服务、信用等级评定等业务。公司于2021年1月8日获得中国人民银行对于企业主体评级的资质备案，正式获批可以开始从事评级相关的业务工作。截止2022年12月，评级团队共输出内部评级分析研报20余份，涉及化工、能源、光电、电子信息等行业，并完成对A股上市3500余家公司基于财务数据和公开数据的主动评级工作，为评级数据积累及主动评级工作奠定了基础。公司以信用评级理念为核心，大数据为基础，通过全息画像工具对相关特征进行提取，开展信用评级模型与信用评级方法的建设工作。',
        subTitle: '基于目前中国经济与资本市场的特别情况，结合国内外在资本市场评估的理论与实践，我们建立了适合中国特色的CAFÉ评估系统，用于支持中国市场的主体信用评级和对应的评估工作。',
        content: 'CAFÉ全息风险评估体系是基于大数据思维，立足于企业公开信息，在异构异源大数据有机融合的基础上，创新性地利用金融科技的全息画像手段，通过对企业进行全方位的“风险基因”特征解读，运用大数据特征指标提取工具与全息画像工具构建的企业动态风险评估体系。该体系全维度核心内容主要有下面四个方面：',
        mainFour:[
            '(1) 公司全息画像风险评估（Corporate assessment hologram,denoted by“C”）',
            '(2) 财务全息画像风险分析（Accounting assessment hologram,denoted by“A”）',
            '(3) 金融行为全息画像风险分析（Financial behavior hologram,denoted by“F”）',
            '(4) 商务生态全息画像风险分析（Ecosystem hologram, denoted by“E”）'
        ],
        objective: '我们利用“CAFÉ全息风险评估体系”结合传统的评级方法和数据，致力于为用户提供实时、动态、准确的评级结果，客观的反应企业或相关债项的特征表现。'
    };