import fetch from '../../../utils/fetch';
import { API } from '../../../constants/enum';

// /api/v1.0/eco/portal/infoDisc/queryPage
export function getList(params) {
    return fetch(`${API.V1}/eco/portal/fileInfo/queryPage`, params, {
        method: 'POST', // 'POST'
        headers: {
            'Content-Type': 'application/json; charset=utf-8' // 以json格式传输
        }
    });
}