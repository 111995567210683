import App from './App';
const BrowserRouter = require('react-router-dom').BrowserRouter;
import React from 'react';
import { hydrate, render } from 'react-dom';

const renderTo = module.hot ? render : hydrate;
renderTo(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
