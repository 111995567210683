import fetch from '../../utils/fetch';
import { API } from '../../constants/enum';

// 全局搜索接口
export function queryPage(params) {
    return fetch(`${API.V1}/eco/portal/fileInfo/queryPage`, params, {
        method: 'POST', // 'POST'
        headers: {
            'Content-Type': 'application/json; charset=utf-8' // 以json格式传输
        }
    });
}