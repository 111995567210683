import {message, Popover} from 'antd';
import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import { timestampToTime, replaceStr} from '../utils/utils';
export const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18}
};

export const formItemLayoutCol = {
    labelCol: {span: 3},
    wrapperCol: {span: 21}
};

export const detailLayoutCol = {
    labelCol: {span: 6},
    wrapperCol: {span: 18}
};

export const investDetailLayoutCol = {
    labelCol: {span: 5},
    wrapperCol: {span: 19}
};

export const UPLOAD_CONFIG = {
    name: 'file',
    headers: {
        authorization: 'authorization-text'
    },
    beforeUpload: file => {
        const isJPG =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isJPG) {
            message.error('目前仅支持图片上传!');
        }

        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('图片大小不能超过5MB!');
        }
        return isJPG && isLt2M;
    },
    multiple: false,
    showUploadList: false
};
// 信息披露
export const COLUMNS_INFO = [
    // { 
    //     title: '文件类型', 
    //     dataIndex: 'fileTypeName',
    //     render: (text, record)=><div className="fileName" title={text}>{text || '--'}</div>
    // },
    { title: '编号', dataIndex: 'code', width: '20%'},
    {
        title: '文件名',
        dataIndex: 'fileTitle',
        render: (text, record)=><Popover content={replaceStr(text)} trigger="hover"><div className="fileName">{text || '--'}</div></Popover>
    },
    { 
        title: '日期', 
        dataIndex: 'gmtCreate',
        width: '20%',
       render: (text, record)=>text ? timestampToTime(text) : '--'
     }
];
// 评级
export const ASSESS_INFO = [
    // { 
    //     title: '文件类型', 
    //     dataIndex: 'fileTypeName',
    //     render: (text, record)=><div className="fileName" title={text}>{text || '--'}</div>
    // },
    { title: '编号', dataIndex: 'code',width: '20%'},
    { 
        title: '文件名',
        dataIndex: 'fileTitle',
        width: '35%',
        render: (text, record)=><Popover content={replaceStr(text)} trigger="hover"><div className="fileName" >{text || '--'}</div></Popover>
    },
    { 
        title: '日期',
        dataIndex: 'gmtCreate',
        width: '19%',
        render: (text, record)=>text ? timestampToTime(text) : '--' 
    }
];
