import style from './style.scss';
import React from 'react';
import { Typography } from 'antd';
/**
 * @desc 底部
 */

export default function(props) {
    return (
        <div className={style.footer}>
            <div className={style.center}>
                <Typography> Copyright  © 2019  BusinessBigData. All Rights Reserved  <a className={style.gray} href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20021934号-1</a></Typography>
                <Typography> 建议分辨率：1920*1080及以上 建议浏览器：Chrome浏览器能带来更好使用体验 Chrome及最新IE浏览器 
                    <a target="_blank" href="https://browsehappy.com/" className='download'>下载</a></Typography>
                {/* <Typography>川公网安备 510190020XXXXX号</Typography> */}
            </div>
        </div>
    );
}
