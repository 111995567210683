import React, { useState, useContext, useEffect } from 'react';

import style from './style.scss';
import { Table, ConfigProvider, Popover } from 'antd';
import NoTableData from '../../common/noTableData';

import { timestampToTime, replaceStr} from '../../utils/utils';
import {searchInputContext} from '../router/hook';
import {queryPage} from './service';

export default function(props) {
    const {onchange, query, onQuery, value} = useContext(searchInputContext);
    const noData = [null, undefined, ''];
    let columns = [
        { title: '文件类型', dataIndex: 'fileTypeName'},
        { title: '编号', dataIndex: 'code',width: '20%'},
        { 
            title: '文件名',
            dataIndex: 'fileTitle',
            width: '38%',
            render: (text, record)=><Popover content={replaceStr(text)} trigger="hover"><div className="fileName"  dangerouslySetInnerHTML={{ __html: text || '--' }}></div></Popover>
        },
        { 
            title: '日期',
            dataIndex: 'gmtCreate',
            width: '20%',
            render: (text, record)=>text ? timestampToTime(text) : '--' 
        }
    ];
    columns = [...columns.map(item => ({ ...item, render: item.render ? item.render : (text) => noData.includes(text) ? '--' : text }))];

    let [tableData, setTableData ] = useState([]);
    let [loading, setLoading ] = useState(false);
    let [total, setTotal ] = useState(0);
    let [pageNum, setPageNum ] = useState(1);

    // 页码修改
    const onPageChange = (num) => {
        setPageNum(num);
        getTableList(num)
    }

    useEffect(()=>{
        if(query !== value){return;}
        // 设置当前页码为1
        setPageNum(1); 
        getTableList(1)
    },[query])
    // 搜索列表
    const getTableList = (pageNum)=>{
        setLoading(true);
        let params = {
            query: query && query.trim() || '',
            fileType: '',
            systemType: '',
            pageNum,
            pageSize: 10
        } 
        queryPage(params).then(res=>{
            setTableData(res && res.data && res.data.data);
            setTotal(res && res.data && res.data.totalCount);
            setLoading(false);
        },(err)=>{
            setTableData([]);
            setTotal(0); 
            setLoading(false);           
        });
    }
     // 当前行
    const onRowClick = (item)=>{
        // const {match } = props;
        // let url = match.path;       
        var path = {
            pathname: `fileDetail/${item.id}`,
            state: item
        };
        props.history.push(path);         
    } 

    return <div className={style.searchList}>
        <div className={style.content}>
            <ConfigProvider renderEmpty={()=><NoTableData/>}>
                    <Table
                        rowKey='id'
                        columns={columns}
                        loading={loading || false}
                        dataSource={tableData || []}
                        onRow={record => {
                            return {
                                onClick: () => onRowClick(record)
                            };
                        }}
                        pagination={{
                            total: total,
                            current: pageNum,
                            pageSize: 10,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}
                    />
            </ConfigProvider>
        </div>      
    </div>;
}