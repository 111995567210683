/**
 * @author Ray
 */

import React, { useState } from 'react';
import { Routes } from '../../router';
import styles from './styles.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

export default function({ match, history }) {
    const [activeItem, setActiveItem] = useState(function() {
        let { path } = match;

        path = path.replace('/', '');
        return path;
    });

    return (
        <div className={styles.menuRoot}>
            {Routes.map(function({ name, path, visible }) {
                return visible ? (
                    <div
                        key={path}
                        className={classnames(styles.menuItem, {
                            [styles.menuActive]: activeItem === path
                        })}
                    >
                        <Link to={`/${path}`}>{name}</Link>
                    </div>
                ) : null;
            })}
        </div>
    );
}
