import React from 'react';
import Model from './model';
import View from './view';
import Page from './page';

import filePng from '../images/file.png';
import activefilePng from '../images/activeFile.png';
import basicInfoPng from '../images/basicInfo.png';
import activeBasicInfoPng from '../images/activeBasicInfo.png';
import formancePng from '../images/formance.png';
import activeFormancePng from '../images/activeFormance.png';
import specialInfoPng from '../images/specialInfo.png';
import activeSpecialInfoPng from '../images/activeSpecialInfo.png';
import ratingSystemPng from '../images/ratingSystem.png';
import activeRatingSystemPng from '../images/activeRatingSystem.png';
import buildPng from '../images/build.png';
import activeBuildPng from '../images/activeBuild.png';
 const Routes = [
    {   
        path: 'registerFile',
        name: '注册文件',
        hasChild: false,
        icon: filePng,      // 菜单 图标
        activeIcon: activefilePng, // 激活图标
        type: 1,            // 接口参数【列表】
        component: Page,
        exact: false
    },
    {   
        path: 'basicInfo',
        name: '公司基本信息',
        hasChild: false,
        icon: basicInfoPng,
        activeIcon: activeBasicInfoPng,
        type: 2,
        component: Page,
        exact: false
    },
    {   
        path: 'system',
        name: '评级相关制度',
        hasChild: true,
        icon: activeRatingSystemPng,
        activeIcon: ratingSystemPng,
        component: Page,
        exact: false,
        children:[
            {
                path: 'internalControlSystem',
                name: '内控制度', 
                type: 3
                // component: Page,
            },
            {
                path: 'businessSystem',
                name: '业务制度', 
                type: 4
                // component: Page,
            },
        ]       
    },
    {   
        path: 'complianceConstruction',
        name: '合规建设',
        hasChild: false,
        icon: buildPng,
        activeIcon: activeBuildPng,
        type: 5,
        component: Page,
        exact: false
    },
    {   
        path: 'ratingPerformance',
        name: '评级表现',
        hasChild: false,
        icon: formancePng,
        activeIcon: activeFormancePng,
        type: 6,
        component: Page,
        exact: false
    },
    {   
        path: 'specialdisclosureInfo',
        name: '专项信息披露',
        hasChild: false,
        icon: specialInfoPng,
        activeIcon: activeSpecialInfoPng,
        type: 7,
        component: Page,
        exact: false
    }
];
export default function(props) {
    return <Model>{prop => <View {...props} {...prop} route = {Routes}/>}</Model>;
}