import fetch from '../../utils/fetch';
import { API } from '../../constants/enum';

//  /api/v1.0/eco/portal/banner/queryBanner
export function getBanner() {
    return fetch(`${API.V1}/eco/portal/banner/queryBanner`, {}, {
        method: 'GET' // 'POST'
        // headers: {
        //     'Content-Type': 'application/json; charset=utf-8' // 以json格式传输
        // }
    });
}

// 测试
export function test() {
    return new Promise(function(resolve, reject) {
        setTimeout(function() {
            resolve('admin');
        }, 1000);
    });
}