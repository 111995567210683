import React from 'react';
import style from './style.scss';
import Menus from './menus';
import User from './user';
import logo from '../images/logo.png';
/**
 * @desc 头部
 */

export default function(props) {
    let { match } = props;
    return (
        <div className={style.container}>
            <div className={style.header}>
                <img src={logo} className={style.logo}/>
                <Menus {...props} />
               {
                   match.path !== '/home' ?<User {...props} /> : null
               }                
            </div>
        </div>
    );
}
