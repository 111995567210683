import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import Frame from '../frame';
import Home from '../home';
import DisclosureInfo from '../disclosureInfo';
import RatingSystem from '../ratingSystem';
import SearchList from '../searchList';
import FileDetail from '../fileDetail';
import hook, {searchInputContext} from './hook';
export const Routes = [
    // {   // 关于我们
    //     path: '',
    //     name: '关于我们', // 导航菜单对应名字
    //     visible: false, // 是否需要在页面展示，因为部分菜单名字可能不需要在页面展示，比如登录页
    //     component: Home,
    //     exact: true
    // },
    {   // 关于我们
        path: 'home',
        name: '关于我们', 
        visible: true, 
        component: Home,
        exact: false
    },
    {   // 信息披露
        path: 'disclosureInfo',
        name: '信息披露',
        visible: true,
        component: DisclosureInfo,
        exact: false
    },
    {   // 评级体系
        path: 'ratingSystem',
        name: '信用评级',
        visible: true,
        component: RatingSystem,
        exact: false
    },
    {   // 搜索列表
        path: 'searchList',
        name: '搜索列表',
        visible: false,
        component: SearchList,
        exact: false
    },
    {   // 搜索列表
        path: 'fileDetail/:id',
        name: '详情',
        visible: false,
        component: FileDetail,
        exact: false
    }
];

export default function () {
    const {query, onchange, value, onQuery} = hook();
    return (
        <searchInputContext.Provider value={{query, onchange, value, onQuery}}>
            {/* <BrowserRouter> */}
                <Switch>
                    {
                        Routes.map(o =>
                            <Route
                                key={o.path}
                                exact={o.exact}
                                path={`/${o.path}`}
                                render={
                                    prop => <Frame {...prop} route={o} />
                                }
                            />
                        )
                    }
                    <Redirect to ={'home'}/>
                </Switch>
            {/* </BrowserRouter> */}
        </searchInputContext.Provider>

    );
}
