import React from 'react';
import 'antd/dist/antd.css';
import './styles/common.css';
import './styles/reset.scss';
import Routes from './containers/router';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import {ConfigProvider} from 'antd';

const App = () => (
    <ConfigProvider locale={zhCN}>
        <Routes />
    </ConfigProvider>
);

export default App;
