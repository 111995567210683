import React, {Component} from 'react';
import style from './style.scss';
import { Carousel } from 'antd';

import banner from '../images/banner.png';
import carouselOne from '../images/carouselOne.png';
import carouselTwo from '../images/carouselTwo.png';
import carouselThree from '../images/carouselThree.png';
import {BannerData } from '../../constants/banner';
import {getBanner } from './service';
// 关于我们
// const BaseUrl = '//file.bbdService.com';
export default class index extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {}
        };
    }
    componentDidMount(){
       //  this.getData();
    }
    getData = async()=>{
        const res = await getBanner();
       this.setState({
        data: res.data
       })
        
    }
    render() {
        // let { data } = this.state;
        return (
            <div className={style.home}>
            <img src={banner} className={style.banner}/>
            <div className={style.content}>
                <div className={style.companyName}>{BannerData.companyName}</div>
                <div className={style.carousel}>
                    <div className={style.left}>
                        <h2>{BannerData.title}</h2>
                        <p>{BannerData.desc}</p>
                        <p>{BannerData.subTitle}</p>
                    </div>
                    <Carousel autoplay={true}>
                        <img src={carouselOne}/> 
                        <img src={carouselTwo}/>
                        <img src={carouselThree}/> 
                    </Carousel>               
                    <p className={style.second}>{BannerData.content}</p>
                    <ul>
                         {
                            BannerData && BannerData.mainFour.map(item=>{
                                return(
                                    <li key={item}>{item}</li>
                                )
                            })
                        }
                    </ul>
                    <p className={style.second}>{BannerData.objective}</p>
                </div>
            </div>
        </div>
        )
    }
}
