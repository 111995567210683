import {message} from 'antd';

/**
 * 过滤参数，过滤空字符串
 * */
export function paramFilter(params = {}) {
    let result = {};
    for (let k in params) {
        if (params[k] !== undefined && params[k] !== null) {
            result[k] = window.encodeURIComponent(params[k]);
        }
    }
    return result;
}

export function getRequestQuery(query) {
    const res = [];
    Object.entries(query).forEach(o => res.push(`${o[0]}=${o[1]}`));
    return res.join('&');
}

export function isSpecialSon(target, className) {
    let flag = false; // 默认为否
    let selfTarget = null;
    // 闭包 用以公用变量
    function fn(_target) {
        // 判断target是否有特定的className

        // 递归的出口
        if (flag || !_target) {
            return;
        }
        if (!_target.classList) {
            return;
        }

        if (_target.classList.contains(className)) {
            flag = true;
            selfTarget = _target;
        } else {
            // 递归的入口
            fn(_target.parentNode);
        }
    }

    fn(target);
    return {flag, selfTarget};
}

/* 截取地址栏跳转过来的参数  返回的params是对象*/
export function addressNum(k) {
    let str = k.history.location.search;
    let params = (str => {
        if (typeof str === 'string') {
            str = str.substr(1);
            let strArr = str.split('&');
            let params = null;
            if (Array.isArray(strArr)) {
                params = {};
                strArr.forEach(item => {
                    let _arr = item.split('=');
                    params[_arr[0]] = _arr[1];
                });
            }
            return params;
        }
    })(str);

    return params;
}
// 全局提示配置
export function errConfig(type, msg) {
    message.destroy();
    message.config({
        top: 60
    });
    if (type === 'success') {
        message.success(msg);
    } else if (type === 'error') {
        message.error(msg);
    }
}
// 限制显示字数，超出省略号显示
export function LimitNumber(txt, limitNum) {
    var str = txt;
    if (typeof str === 'string' && str !== 'null') {
        if (str.length > limitNum) {
            str = str.substr(0, limitNum) + '...';
        }
    }
    return str;
}

/**
 * @desc 函数节流
 * @url http://underscorejs.org/#throttle
 * @param {string} func 防抖函数
 * @param {string} wait 间隔时间
 * @param {string} options 可选项
 */
export function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) {
        options = {};
    }

    var later = function() {
        previous = options.leading === false ? 0 : +new Date();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) {
            context = args = null;
        }
    };

    return function() {
        var now = +new Date();
        if (!previous && options.leading === false) {
            previous = now;
        }
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) {
                context = args = null;
            }
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}

/** *
 * @description 防抖函数
 */
export function debounce(fn, delay, scope) {
    let timer = null;
    // 返回函数对debounce作用域形成闭包
    return function() {
        // setTimeout()中用到函数环境总是window,故需要当前环境的副本；
        let context = scope || this,
            args = arguments;
        // 如果事件被触发，清除timer并重新开始计时
        clearTimeout(timer);
        timer = setTimeout(function() {
            fn.apply(context, args);
        }, delay);
    };
}

/**
 * 过滤params为null的
 * @param obj
 * @returns {{}}
 */
export function dealParam(obj) {
    let param = {};
    if (obj === null || obj === undefined || obj === '') {
        return param;
    }
    for (var key in obj) {
        if (
            obj[key] !== null &&
            obj[key] !== undefined &&
            obj[key] !== '' &&
            obj[key] !== 'all' &&
            obj[key] !== '请选择'
        ) {
            param[key] = obj[key];
        }
    }
    return param;
}

/**
 * 如果标题字数超出指定长度，则多余部分显示...
 * @param {number} num
 * @param {string} str
 */
export function limitString(num, str) {
    if (str && str.length > num) {
        return str.substring(0, num) + '...';
    }
    return str;
}

/**
 * 判断字段是否异常，如果异常则返回‘--’
 * @param value
 * @returns {*}
 */
export function fieldAnomaly(value) {
    if (
        (typeof value === 'number' && !isNaN(value)) ||
        (typeof value === 'string' && value.trim().length > 0)
    ) {
        return value;
    }

    if (!value) {
        return '--';
    }
    return value;
}

// get 参数拼接
export function convertQueryString(params) {
    let query = '';
    for (let key in params) {
        if (params[key] || params[key] === 0) {
            if (query.indexOf('?') === -1) {
                query = query + `?${key}=${params[key]}`;
            } else {
                query = query + `&${key}=${params[key]}`;
            }
        }
    }
    return query;
}

// 加密名字
export function encryptName(name) {

    if(isEmpty(name)) {return '';}

    return name.replace(name.slice(1), '**');
}

// 加密手机号
export function encryptNumber(number) {

    if(isEmpty(number)) {return '';}

    return number.replace(number.slice(3, 7), '****');
}

// 内部函数, 用于判断对象类型
function _getClass(object) {
    return Object.prototype.toString
        .call(object)
        .match(/^\[object\s(.*)\]$/)[1];
}

export function isArray(obj) {
    return _getClass(obj).toLowerCase() === 'array';
}

export function isString(obj) {
    return _getClass(obj).toLowerCase() === 'string';
}

export function isDate(obj) {
    return _getClass(obj).toLowerCase() === 'date';
}

export function isObject(obj) {
    return _getClass(obj).toLowerCase() === 'object';
}

export function isNumber(obj) {
    return _getClass(obj).toLowerCase() === 'number';
}

/**
 * @desc 判断参数是否为空, 包括null, undefined, [], '', {}
 * @param {object} obj 需判断的对象
 */
export function isEmpty(obj) {
    var empty = false;

    if (obj === null || obj === undefined) {
        // null and undefined
        empty = true;
    } else if ((isArray(obj) || isString(obj)) && obj.length === 0) {
        empty = true;
    } else if (isObject(obj)) {
        var hasProp = false;
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                hasProp = true;
                break;
            }
        }
        if (!hasProp) {
            empty = true;
        }
    } else if (isNumber(obj) && isNaN(obj)) {
        empty = true;
    }
    return empty;
}

// 脱敏
export function desense(str, from = 3, len = 4) {
    if(!str) {
        return str;
    }
    const reg = new RegExp(`^(.{${from}}).{${len}}`, 'g');
    return str.replace(reg, '$1****');
}

/**
 * 千分位
 * @return {string}
 */
export function formatNumber(num, unit = 1) {
    if (isNaN(parseFloat(num))) {
        return '0';
    }
    return ('' + (num * 100 / unit).toFixed(unit === 1 ? 0 : 1) / 100).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
}
// 格式化金额
export function money(k){
    if(k){
        // let a = k.split('.')[0];
        let b = k.toString().indexOf('.') === -1 ? k.toString().split('').reverse() : k.toString().split('.')[0].split('').reverse();   
        for(var i = 0; i < b.length; i++){
            if((i + 1) % 4 === 0){
                b.splice(i, 0, ',');
            }
        }
        b.reverse(); 
        var str = '';
        for(var c = 0; c < b.length; c++){
            str += b[c];	
        }
        return str + (k.toString().indexOf('.') === -1 ? '' : '.' + k.toString().split('.')[1]);
    }else{
        return 0;
    }
}

// 转日期
export function timestampToTime(timestamp) {
    if (!timestamp) {
        return '';
    }
    const addZero = (s) => +s < 10 ? `0${s}` : s;
    const date = new Date(timestamp),
        Y = date.getFullYear() + '-',
        M = addZero((date.getMonth() + 1)) + '-',
        D = addZero(date.getDate()) + ' ',
        h = addZero(date.getHours()) + ':',
        m = addZero(date.getMinutes()) + ':',
        s = addZero(date.getSeconds());
    return Y + M + D;
}
// html标签/替换符 替换 '' 
export function replaceStr(str) {
    if (str) {
        return str.replace(/<[^>]*>|(&nbsp;)*/g, '');
    }
}