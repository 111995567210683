import React, { useState, useEffect } from 'react';
/**
 * @desc 
 */

// 信息存入context
export const searchInputContext = React.createContext({});

export default function(props) {
    const [query, setQuery] = useState(null);
    const [value, setValue] = useState(null);
    const onchange =(value)=>{
        setValue(value);
    }
    function onQuery() {
        setQuery(value);
    }
    
    return {
        query, onchange, value, onQuery
    }
}
