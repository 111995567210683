import React, {Fragment, useEffect, useState, useContext} from 'react';
import styles from './style.scss';
import { Route, Switch, Redirect, Link, withRouter} from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import {searchInputContext} from '../router/hook';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);
const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const init = 'ratingBasicFile/ratingControlSystem';
export default withRouter(function(props) {
    const {onchange, query, onQuery} = useContext(searchInputContext);
    const { match } = props;
    let  current = init;
    let basePath = props.history.location.pathname;
    let breadName = '';
    let firstName = '';
    let childName = '';
    let child= null;
    if (basePath) {
       var arr = basePath.split('/');
        firstName= arr[2];      
        current = arr && arr[arr.length-2];      
        props.route.find(item=>{
           if ( item.path === firstName) {
            current = item.path;
            breadName = item.name;
            child = item;
           }
        });
        child && child.children && child.children.find((item=>{
            if ( item.path === arr[3] && arr[3] !== 'list') {
                current = item.path;
                childName = item.name;
               }
        }))
             
    }
    useEffect(() => {
        onchange('');
    }, [basePath]);
    
    return (
        <div className={cx({layout: true})}>
            <div className={cx({sider: true})}>
            <div className={cx({title:true})}>信用评级</div>
            
                <Sider width={200}>
                <Menu
                    mode="inline"
                    selectedKeys={[current || init]}
                    style={{ borderRight: 0 }}
                    >
                    
                        {
                            props.route && props.route.map((item, index) => {
                                return (
                                    item.hasChild ? (
                                        <SubMenu
                                            key={item.path}
                                            title={<span>{item.icon ? <img src={(props.location.pathname).indexOf(item.path) !==-1 || current === item.path ? item.activeIcon : item.icon}/> : null}&ensp;<span>{item.name}</span></span>}
                                        >
                                            {
                                                item.children && item.children.map(child=>{
                                                    return (                   
                                                            <Menu.Item  key={child.path} ><Link to={`${match.path}/${item.path}/${child.path}`}>{child.icon ? <img src={child.icon}/> : null}{child.name}</Link></Menu.Item>
                                                    )
                                                })
                                            }
                                        </SubMenu>
                                       
                                    ) :
                                    (<Menu.Item  key={item.path} ><Link to={`${match.path}/${item.path}`}><img src={current === item.path ? item.activeIcon : item.icon}/>{item.name}</Link></Menu.Item>)
                                );
                            })
                        }
                    </Menu>
                </Sider>
            </div>
            <div className={cx({content: true})}>
               <Breadcrumb separator=">">
                <Breadcrumb.Item>信用评级</Breadcrumb.Item>
                <Breadcrumb.Item className={cx( {breadName: true})}>{breadName}</Breadcrumb.Item>
                <Breadcrumb.Item className={cx( {breadName: true})}>{childName}</Breadcrumb.Item>
                </Breadcrumb>
                <Switch>
                    {
                       props.route && props.route.map(o => {
                           return(
                               o.hasChild ? 
                               (
                                   o.children.map(child=>
                                    <Route
                                    key={child.path}
                                    exact={child.exact}
                                    path={`${match.path}/${o.path}/${child.path}`}                             
                                    render={
                                        prop => <o.component {...prop} fileType={child.type} Routes={child.route} current={current}/>
                                    }
                                />)
                               )
                               : 
                                <Route
                                    key={o.path}
                                    exact={o.exact}
                                    path={`${match.path}/${o.path}`}                             
                                    render={
                                        prop => <o.component {...prop} fileType={o.type} Routes={props.route} current={current}/>
                                    }
                                />
                           )
                       })                                                   
                    }
                    <Redirect from={match.path} to={`${match.path}/${init}`} />
                </Switch>
            </div>                    
        </div>
    );
})