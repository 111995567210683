/**
 * @author Ray
 */
import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.scss';
import {Input  } from 'antd';
import classnames from 'classnames/bind';

import hook, {searchInputContext} from '../../router/hook';

const cx = classnames.bind(styles);
const { Search } = Input;

export default function(props) {
    const { path, match, location } = props;

    const {onchange, query, onQuery, value} = useContext(searchInputContext);
    const handleChange = (value)=>{
        onQuery();
        props.history.push('/searchList');
        
    }

    return (
        <div className={cx({ userRoot: true, show: match.path === '/home' })}>
            <Search
                placeholder="请输入关键字搜索"
                onSearch={handleChange}
                style={{ width: 300 }}
                value={value}
                onChange={(e) =>onchange(e.target.value)}
            />
        </div>
    );
}
