// http协议method
export const HttpMethod = {
    GET: 'get',
    POST: 'post'
};

// http协议头Content-Type
export const ContentType = {
    FILE: 'file', // 自定义的文件类型
    JSON: 'application/json',
    FORM_URLENCODED: 'application/x-www-form-urlencoded'
};

export const API = {
    V1: '/api/v1.0'
};

export const SERVER = '';

// export const SERVER = 'http://10.28.109.127:8088';

export const PERMISSION_CODE = {
    bank: ['bankCode', 'equityCode', 'guaranteeCode'],
    gov: ['ROLE_BJJK_GOV'],
    company: ['ROLE_BJJK_COMPANY']
};
//     ROLE_BJJK_COMPANY   企业用户角色code
//   bankCode/equityCode/guaranteeCode   金融用户角色（银行/投资机构/担保）
//   ROLE_BJJK_OPERATE   运营用户角色code
//   ROLE_BJJK_GOV   政府用户角色code  
//   ROLE_BJJK_GUARANTEE   担保用户角色code
