import React from 'react';
import style from '../../styles/var.scss';
import { withRouter} from 'react-router-dom';
import { Divider, message } from 'antd';
import attach from '../images/attach.png';
import { timestampToTime, replaceStr} from '../../utils/utils';

export default withRouter(function(props) {
    const {location: {state}, match } = props;
    const baseUrl = '//file.bbdservice.com';
    const openFile = (url)=>{
        if (url) {
            window.open(url);
        }
    }
    return <div className={style.detail + ' ' + style.fileDetail}>
                <div className={style.content}>
                    <h3>{state && state.code || '--'}&emsp;{replaceStr(state && state.fileTitle) || '--'}</h3>
                    <p>发布时间：{state && timestampToTime(state.gmtCreate) || '--'}</p>
                    <Divider /> 
                    <strong><img src={attach}/> <span onClick={()=>openFile(state && state.filePath)}>{replaceStr(state && state.fileTitle)}</span></strong>                
                </div>    
            </div>;
    })