import React, {Component, useEffect, useState, useContext} from 'react';
import List from '../../../../common/list';
import { getList } from '../service';

export default function(props){

    let [total, setTotal] = useState(0);
    let [pageNum, changePageNum] = useState(1);
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);

    useEffect(()=>{
        getLists();
    },[pageNum]);

    // 页码改变
    const pageChanges = (num)=>{
        changePageNum(num);
    }
     // 当前行
     const onRowClicks = (item)=>{
        const {match } = props;
        let url = match.path;         
        var path = {
            pathname: `${url}/detail/${item.id}`,
            state: item
        };
        props.history.push(path);         
    } 

    // 列表数据
    const getLists = async()=>{
        setLoading(true);
        // setData([]);
        
        let params = {fileType: props.fileType, query: '', systemType: 2, pageNum: pageNum, pageSize: 10}; 
        try {
            let res = await getList(params);
            setData(res.data.data || []);
            setTotal(res.data.totalCount);
            setLoading(false);
        } catch (error) {
            setData([]);
            setTotal(0);
            setLoading(false);
        }
    } 

    const datas = {data, pageNum, pageSize: 10, total, loading};
       
        return ( <List {...props} {...datas} columnType = {1} pageChange = {pageChanges} onRowClick ={onRowClicks}/>)
}