import React from 'react';
import Model from './model';
import View from './view';
import Page from './page';
import announcementPng from '../images/announcement.png';
import activeAnnouncementPng from '../images/activeAnnouncement.png';
import basicFilePng from '../images/basicFile.png';
import activeBasicFilePng from '../images/activeBasicFile.png';
import productsPng from '../images/products.png';
import activeProductsPng from '../images/activeProducts.png';
import researchPng from '../images/research.png';
import activeResearchPng from '../images/activeResearch.png';
import othersPng from '../images/others.png';
import activeOthersPng from '../images/activeOthers.png';
// 评级体系
const Routes = [
    {   // 评级基础文件
        path: 'ratingBasicFile',
        name: '评级基础文件',
        component: Page,
        hasChild: true,       
        icon: basicFilePng,             // 菜单 图标
        activeIcon: activeBasicFilePng,
        exact: false,
        children:[
            {
                path: 'ratingControlSystem',
                name: '评级业务与内控制度', 
                type: 10              // 接口参数【列表】
                // component: Page,
            },
            {
                path: 'ratingProcessMethod',
                name: '评级过程与评级方法', 
                // component: Page,
                type: 11, 
            },
        ]
    },
    {   // 评级产品
        path: 'ratingProducts',
        name: '评级产品',
        icon: productsPng,
        activeIcon: activeProductsPng,
        component: Page,
        type: 12,
        hasChild: false,
        exact: false
    },
    {   // 评级公告
        path: 'ratingAnnouncement',
        name: '评级公告',
        icon: announcementPng,
        activeIcon: activeAnnouncementPng,
        component: Page,
        type: 13,
        hasChild: false,
        exact: false
    },
    {   // 信用研究
        path: 'creditResearch',
        name: '信用研究',
        icon: researchPng,
        activeIcon: activeResearchPng,
        component: Page,
        type: 14,
        hasChild: false,
        exact: false
    },
    {   // 其他
        path: 'others',
        name: '其他',
        icon: othersPng,
        activeIcon: activeOthersPng,
        component: Page,
        type: 15,
        hasChild: false,
        exact: false
    }
];
export default function(props) {
    return <Model>{prop => <View {...props} {...prop} route = {Routes}/>}</Model>;
}